import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedOrgRoute from "./routes/ProtectedOrgRoute";

import LoginPage from "../pages/login/LoginPage";
import HomePage from "../pages/home/HomePage";
import DashboardPage from "../pages/home/DashboardPage";
import ClientsPage from "../pages/clients/ClientsPage";
import ClientPage from "../pages/clients/ClientPage";
import ManagersPage from "../pages/managers/ManagersPage";

import PrintCheckPage from "../pages/home/PrintCheckPage"

import OrgsPage from "../pages/orgs/OrgsPage";
import ProductsPage from "../pages/products/ProductsPage";
import ProductsCategoryAddPage from "../pages/products/ProductsCategoryAddPage";
import ProductsCategorys from "../pages/products/ProductsCategorys";

import MaterialsPage from "../pages/materials/MaterialsPage";
import MaterialAddPage from "../pages/materials/MaterialAddPage";

import ProductsAddPage from "../pages/products/ProductsAddPage";
import ArchivePage from "../pages/archive/ArchivePage";
import SettingPage from "../pages/setting/SettingPage";
import StatisticPage from "../pages/statistic/StatisticPage";

function AppNavigator() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/clients"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" >
              <ClientsPage />
            </ProtectedOrgRoute>

          </ProtectedRoute>
        }
      />
      <Route
        path="/client"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" ><ClientPage /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />
      <Route
        path="/managers"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" ><ManagersPage /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />

      <Route
        path="/orgs"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" ><OrgsPage /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />
      <Route
        path="/products/category/:id"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" ><ProductsPage /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />


      <Route
        path="/category/all"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" ><ProductsCategorys /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />

      <Route
        path="/add/category"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" ><ProductsCategoryAddPage /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />

      <Route
        path="/products/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" ><ProductsAddPage /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />

      <Route
        path="/material/list"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" ><MaterialsPage /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />

      <Route
        path="/material/add"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" ><MaterialAddPage /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />

      <Route
        path="/material/all"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" > <MaterialsPage /></ProtectedOrgRoute>
           
          </ProtectedRoute>
        }
      />





      <Route
        path="/archive"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0,1" ><ArchivePage /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />
      <Route
        path="/setting"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0" ><SettingPage /></ProtectedOrgRoute>
            
          </ProtectedRoute>
        }
      />
      <Route
        path="/statistic"
        element={
          <ProtectedRoute>
            <ProtectedOrgRoute type="0" >
            <StatisticPage />
            </ProtectedOrgRoute>
           
          </ProtectedRoute>
        }
      />

      <Route
        path="/deal/check/:id"
        element={
          <ProtectedRoute>
            <PrintCheckPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default AppNavigator;
